import { zodResolver } from '@hookform/resolvers/zod';
import { Close, ConfirmationNumber, HelpOutline, Videocam } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Popover, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import base from '../apis';
import { ticketSchema } from '../schema';

const products = ['BPO Realty Agent', 'Premier Agent', 'BPO Investor List', 'BPO Report'];
const message_type = ['Billing', 'Technical Support', 'Realty Support', 'Other Support'];

const Help = () => {
	const [helpAnchor, sethelpAnchor] = useState(false);
	const handleHelpClick = (event) => {
		sethelpAnchor(event.currentTarget);
	};
	const helpOpen = Boolean(helpAnchor);
	const handleHelpClose = () => {
		sethelpAnchor(null);
	};

	const [ticketDialogOpen, setTicketDialogOpen] = useState(false);
	const details = useSelector((state) => state.auth);
	const {
		register,
		formState: { errors },
		reset,
		control,
		handleSubmit,
	} = useForm({
		resolver: zodResolver(ticketSchema),
		defaultValues: {
			number: details.phone,
			email: details.email,
			name: `${details.first_name} ${details.last_name}`,
			product: '',
			message_type: '',
			message: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const handleTicketSubmit = async (values) => {
		try {
			setIsLoading(true);
			await base.post('/register/support-ticket/', values);
			reset();
			toast.success('Ticket raised successfully');
			setTicketDialogOpen(false);
		} catch (error) {
			toast.error('Could not raise ticket');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Dialog fullWidth maxWidth='xs' open={ticketDialogOpen} onClose={() => setTicketDialogOpen(false)}>
				<DialogTitle>
					<Stack sx={{ width: '100%' }}>
						<Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%' }} alignItems='center'>
							<Typography fontWeight={600}>Raise Ticket</Typography>
							<IconButton onClick={() => setTicketDialogOpen(false)}>
								<Close fontSize='small' />
							</IconButton>
						</Stack>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit(handleTicketSubmit)} style={{ paddingTop: '6px' }}>
						<Stack spacing={2}>
							<Controller
								name='product'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										label='Product'
										variant='outlined'
										fullWidth
										select
										size='small'
										error={!!fieldState.error}
										helperText={fieldState.error?.message}
									>
										{products.map((product) => (
											<MenuItem key={product} value={product}>
												{product}
											</MenuItem>
										))}
									</TextField>
								)}
							/>

							<TextField
								{...register('name')}
								label='Name'
								variant='outlined'
								fullWidth
								error={!!errors.name}
								helperText={errors.name?.message}
								size='small'
							/>
							<TextField
								{...register('email')}
								label='Email'
								variant='outlined'
								fullWidth
								error={!!errors.email}
								helperText={errors.email?.message}
								size='small'
							/>
							<TextField
								{...register('number')}
								label='Phone Number'
								variant='outlined'
								fullWidth
								error={!!errors.number}
								helperText={errors.number?.message}
								size='small'
							/>
							<Controller
								name='message_type'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										select
										{...field}
										label='Message Type'
										variant='outlined'
										fullWidth
										size='small'
										error={!!fieldState.error}
										helperText={fieldState.error?.message}
									>
										{message_type.map((element) => (
											<MenuItem key={element} value={element}>
												{element}
											</MenuItem>
										))}
									</TextField>
								)}
							/>
							<TextField
								{...register('message')}
								label='Message'
								variant='outlined'
								fullWidth
								error={!!errors.message}
								helperText={errors.message?.message}
								multiline
								rows={4}
								size='small'
							/>
							<LoadingButton type='submit' variant='contained' color='secondary' fullWidth loading={isLoading}>
								Submit
							</LoadingButton>
						</Stack>
					</form>
				</DialogContent>
			</Dialog>
			<IconButton onClick={handleHelpClick}>
				<HelpOutline fontSize='small' />
			</IconButton>
			<Popover
				open={helpOpen}
				anchorEl={helpAnchor}
				onClose={handleHelpClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Stack sx={{ padding: '1rem', fontSize: '14px', minWidth: '15rem' }} gap={1}>
					<Typography textAlign='center' fontWeight={500}>
						Need Help?
					</Typography>
					<Stack direction='row' gap={2}>
						<Stack alignItems='center' gap='4' style={{ border: '1px solid #e0e0e0', borderRadius: '5px', padding: '10px', flex: 1 }}>
							<Videocam fontSize='large' style={{ color: 'grey' }} />
							<Button
								color='secondary'
								variant='contained'
								sx={{ fontSize: '12px', whiteSpace: 'nowrap' }}
								href='https://8x8.vc/bpoh/seth.baffour'
								target='_blank'
							>
								Start a Meeting
							</Button>
						</Stack>
						<Stack alignItems='center' gap='4' style={{ border: '1px solid #e0e0e0', borderRadius: '5px', padding: '10px', flex: 1 }}>
							<ConfirmationNumber fontSize='large' style={{ color: 'grey' }} />
							<Button
								color='secondary'
								variant='contained'
								sx={{ fontSize: '12px' }}
								onClick={() => {
									setTicketDialogOpen(true);
									reset();
								}}
							>
								Raise Ticket
							</Button>
						</Stack>
					</Stack>
					<Divider>Or</Divider>
					<Typography sx={{ padding: '10px', fontSize: '14px', whiteSpace: 'nowrap' }}>
						Call Customer Service at &nbsp;
						<a href='tel:1888.616.5270' style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--primary-color)' }}>
							1888.616.5270
						</a>
					</Typography>
				</Stack>
			</Popover>
		</>
	);
};

export default Help;
