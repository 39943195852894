import { z } from 'zod';

const itemSchema = z.object({
	id: z.number().optional(),
	replied_quote: z.number().optional(),
	item: z.string().min(2, 'Item must be filled'),
	message: z.string().optional(),
	amount: z.number().min(1, 'Amount is required'),
	quantity: z.number().min(1, 'Quantity is required'),
	total_amount: z.number(),
});

const detailsSchema = z.object({
	first_name: z.string().min(2, 'First name is required'),
	last_name: z.string().min(2, 'Last name is required'),
	email: z.string().email('Enter a valid email'),
	phone_number: z.string().optional(),
});

export const clientSchema = z.array(detailsSchema);

// Define the main schema
export const quoteDataSchema = z.object({
	message: z.string().optional(),
	sub_total: z.number(),
	tax: z.number().optional(),
	tax_rate: z.string(),
	quote_amount: z.number(),
	total: z.number(),
	items: z.array(itemSchema),
});

export const ticketSchema = z.object({
	product: z.string().min(2, 'Product name is required').default(''),
	number: z.string().optional().default(''),
	name: z.string().min(2, 'Name is required').default(''),
	email: z.string().email('Enter a valid email').default(''),
	message_type: z.string().min(2, 'Message type is required').default(''),
	message: z.string().min(2, 'Message is required').default(''),
});
