import React from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import NavbarDropdown from "./NavDropDown";
import { AiFillHome } from "react-icons/ai";
import { useSelector } from "react-redux";
// import { CgMediaLive } from 'react-icons/cg';
import { BsCardChecklist } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import "./leftnavbar.scss";
import { Article } from "@mui/icons-material";

function ClientLeftNav({ onClose }) {
  const Username = useSelector((state) => state.auth.first_name);

  return (
    <div className="left_navbar">
      <h1>Hi, {Username}!</h1>
      <div className="nav_links_wrapper">
        <NavLink className="nav_link " to="client-dashboard" onClick={onClose}>
          <AiFillHome className="nav_icon" />
          <p>Dashboard</p>
        </NavLink>
        <NavLink className="nav_link " to="my-team" onClick={onClose}>
          <FaUsers className="nav_icon" />
          <p>My Team</p>
        </NavLink>
        <NavLink
          className="nav_link "
          to="/client-ordered-report"
          onClick={onClose}
        >
          <Article className="nav_icon" />
          <p>BPO Reports</p>
        </NavLink>

        <NavbarDropdown
          label={({ show }) => (
            <div className="nav_link">
              <BsCardChecklist className="nav_icon" />
              <p>Analyze Deal</p>
              {show ? (
                <FaChevronDown className="drop_icon" />
              ) : (
                <FaChevronRight className="drop_icon" />
              )}
            </div>
          )}
          routesToCheck={[
            "analyze-deal",
            "dealanalyzer-reports/uncompleted-reports",
            "dealanalyzer-reports/completed-reports",
          ]}
        >
          <>
            <NavLink className="nav_link " to="analyze-deal" onClick={onClose}>
              <p>Analyze any Property of Your Choice</p>
            </NavLink>
            <NavLink
              className="nav_link "
              to="dealanalyzer-reports/uncompleted-reports"
              onClick={onClose}
            >
              <p>Uncompleted Reports</p>
            </NavLink>
            <NavLink
              className="nav_link "
              to="dealanalyzer-reports/completed-reports"
              onClick={onClose}
            >
              <p>Completed Reports</p>
            </NavLink>
            {/* <NavLink className='nav_link '>
                    <p>Ordered Pro Forma Reports</p>
                </NavLink>
                <NavLink className='nav_link '>
                    <p>Saved Pro Forma Reports</p>
                </NavLink> */}
          </>
        </NavbarDropdown>
      </div>
    </div>
  );
}

export default ClientLeftNav;
