const URL = process.env.REACT_APP_URL;

export const timeData = [
	'7:00 AM',
	'7:30 AM',
	'8:00 AM',
	'8:30 AM',
	'9:00 AM',
	'9:30 AM',
	'10:00 AM',
	'10:30 AM',
	'11:00 AM',
	'11:30 AM',
	'12:00 PM',
	'12:30 PM',
	'1:00 PM',
	'1:30 PM',
	'2:00 PM',
	'2:30 PM',
	'3:00 PM',
	'3:30 PM',
	'4:00 PM',
	'4:30 PM',
	'5:00 PM',
	'5:30 PM',
	'6:00 PM',
];

const resources = {
	headerTitle: 'Resources',
	route: '',
	option: [
		{
			text: "Buyer's checklist",
			route: '',
		},
		{
			text: 'Resource center',
			route: '',
		},
		{
			text: 'First timer',
			route: '',
		},
		{
			text: 'Housing market',
			route: '',
		},
	],
};

export const choices = [
	{ value: 'today', label: 'Today' },
	{ value: 'yesterday', label: 'Yesterday' },
	{ value: 'this_week', label: 'This Week' },
	{ value: 'last_week', label: 'Last Week' },
	{ value: 'this_month', label: 'This Month' },
	{ value: 'last_month', label: 'Last Month' },
	{ value: 'this_quarter', label: 'This Quarter' },
	{ value: 'last_quarter', label: 'Last Quarter' },
	{ value: 'this_year', label: 'This Year' },
	{ value: 'last_year', label: 'Last Year' },
];

export const propertyList = {
	roof: 'Tile',
	cooling: null,
	style: 'Traditional',
	area: 1043,
	bathsFull: 5,
	bathsHalf: 6,
	stories: 3,
	fireplaces: 1,
	flooring: null,
	heating: 'Radiant Heat',
	bathrooms: null,
	foundation: 'Slab',
	laundryFeatures: 'Area,Electric Dryer Hookup,Individual Room,Washer Hookup',
	occupantName: null,
	ownerName: null,
	lotDescription: 'Private Backyard',
	pool: 'Association,Private,In Ground',
	subType: null,
	bedrooms: 2,
	interiorFeatures: 'Drapes/Curtains/Window Cover, Fire/Smoke Alarm, High Ceiling, Island Kitchen',
	lotSize: '127X146',
	areaSource: 'Floorplans',
	maintenanceExpense: null,
	additionalRooms: 'Recreation,Master Bedroom,See Remarks',
	exteriorFeatures: 'Back Yard Fenced, Covered Patio/Deck, Patio/Deck',
	water: null,
	view: 'Golf Course',
	lotSizeArea: null,
	subdivision: 'Waterstone Springs',
	construction: 'In Kitchen,Stackable,Washer Included',
	lotSizeAreaUnits: null,
	type: 'RES',
	garageSpaces: 8.74556425245327,
	bathsThreeQuarter: null,
	accessibility: 'Manned Gate',
	acres: null,
	occupantType: null,
	subTypeText: null,
	yearBuilt: 1998,
};

export const quesLists = [
	[
		{
			title: 'When do you plan on selling?',
			subTitle: 'Please tell us your ideal timeline for closing the sale.',
			options: ['ASAP', '6 - 12 months', '1 - 3 months', '12+ months', '3 - 6 months'],
			formID: 'sellingPlan',
		},
	],
	[
		{
			title: 'What is the current condition of your home?',
			subTitle: 'Please select an answer',
			options: ['Needs Significant work', 'Needs a little work', 'Tear down'],
			formID: 'currentCondition',
		},
	],
	[
		{
			title: 'Why are you selling your home?',
			subTitle: 'Please select an answer',
			options: ['Upgrading my home', 'Downsizing my home', 'Selling secondary home', 'Retiring', 'Relocating', 'Other'],
			formID: 'whySell',
		},
	],
	[
		{
			title: 'Are you currently working with a real estate agent? ',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'haveAnAgent',
		},
	],
	[
		{
			title: 'Are you also looking to buy a home?',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'wantToBuyAHome',
		},
	],
	[
		{
			title: 'Have you made any improvements to your home?',
			subTitle: 'Please provide any improvement made',
			options: 'improvementForm',
		},
	],
	[
		{
			title: 'We’ll like to know you more:',
			subTitle: '',
			options: 'personalDetailsForm',
		},
	],
];

const buyingOptions = {
	headerTitle: 'Buying Options',
	route: '',
	option: [
		{
			text: 'Buy with Homelytica',
			route: '',
		},
		{
			text: 'Buy with a broker',
			route: '',
		},
	],
};
/**
 * dropdown that appears when a user hovers over buy
 */
const buyList = [
	{
		headerTitle: 'Home For Sale',
		route: '/buy',
		option: [
			{
				text: 'Home for sale',
				route: '',
			},
			{
				text: 'Foreclosure',
				route: '',
			},
			{
				text: 'For sale by owner',
				route: '',
			},
			{
				text: 'Open houses',
				route: '',
			},
		],
	},
	{
		headerTitle: '',
		route: '',
		option: [
			{
				text: 'New construction',
				route: '',
			},
			{
				text: 'Upcoming',
				route: '',
			},
			{
				text: 'Recent home sales',
				route: '',
			},
			{
				text: 'All houses',
				route: '',
			},
		],
	},
	buyingOptions,
	resources,
];

const sellList = [
	{
		headerTitle: 'My Home',
		route: '/sell ',
		option: [
			{
				text: 'A BPO Home Value',
				route: '',
			},
			{
				text: 'My BPO Dashboard',
				route: '',
			},
		],
	},
	{
		headerTitle: 'BPO Selling Options',
		route: '/sell ',
		option: [
			{
				text: 'A BPO Home Value',
				route: '',
			},
			{
				text: 'My BPO Dashboard',
				route: '',
			},
		],
	},
	resources,
];

const analyticsList = [
	{
		headerTitle: '',
		option: [
			{
				text: 'Investors Deal Analyzer',
				route: '',
			},
			{
				text: 'BPO',
				route: '',
			},
			{
				text: 'BPO Farm',
				route: '',
			},
		],
	},
];

const professionalOptions = [
	{ value: 'agent', label: 'Real Estate Agent' },
	{ value: 'realty_agent', label: 'BPO Homes Realty Agent' },
];

const statesShort = [
	{
		label: 'Alaska',
		value: 'AK',
	},
	{
		label: 'Alabama',
		value: 'AL',
	},
	{
		label: 'Arkansas',
		value: 'AR',
	},
	{
		label: 'Arizona',
		value: 'AZ',
	},
	{
		label: 'California',
		value: 'CA',
	},
	{
		label: 'Colorado',
		value: 'CO',
	},
	{
		label: 'Connecticut',
		value: 'CT',
	},
	{
		label: 'Delaware',
		value: 'DE',
	},
	{
		label: 'Florida',
		value: 'FL',
	},
	{
		label: 'Georgia',
		value: 'GA',
	},
	{
		label: 'Hawaii',
		value: 'HI',
	},
	{
		label: 'Iowa',
		value: 'IA',
	},
	{
		label: 'Idaho',
		value: 'ID',
	},
	{
		label: 'Illinois',
		value: 'IL',
	},
	{
		label: 'Indiana',
		value: 'IN',
	},
	{
		label: 'Kansas',
		value: 'KS',
	},
	{
		label: 'Kentucky',
		value: 'KY',
	},
	{
		label: 'Louisiana',
		value: 'LA',
	},
	{
		label: 'Massachusetts',
		value: 'MA',
	},
	{
		label: 'Maryland',
		value: 'MD',
	},
	{
		label: 'Maine',
		value: 'ME',
	},
	{
		label: 'Michigan',
		value: 'MI',
	},
	{
		label: 'Minnesota',
		value: 'MN',
	},
	{
		label: 'Missouri',
		value: 'MO',
	},
	{
		label: 'Mississippi',
		value: 'MS',
	},
	{
		label: 'Montana',
		value: 'MT',
	},
	{
		label: 'North Carolina',
		value: 'NC',
	},
	{
		label: 'North Dakota',
		value: 'ND',
	},
	{
		label: 'Nebraska',
		value: 'NE',
	},
	{
		label: 'New Hampshire',
		value: 'NH',
	},
	{
		label: 'New Jersey',
		value: 'NJ',
	},
	{
		label: 'New Mexico',
		value: 'NM',
	},
	{
		label: 'Nevada',
		value: 'NV',
	},
	{
		label: 'New York',
		value: 'NY',
	},
	{
		label: 'Ohio',
		value: 'OH',
	},
	{
		label: 'Oklahoma',
		value: 'OK',
	},
	{
		label: 'Oregon',
		value: 'OR',
	},
	{
		label: 'Pennsylvania',
		value: 'PA',
	},
	{
		label: 'Rhode Island',
		value: 'RI',
	},
	{
		label: 'South Carolina',
		value: 'SC',
	},
	{
		label: 'South Dakota',
		value: 'SD',
	},
	{
		label: 'Tennessee',
		value: 'TN',
	},
	{
		label: 'Utah',
		value: 'UT',
	},
	{
		label: 'Virginia',
		value: 'VA',
	},
	{
		label: 'Vermont',
		value: 'VT',
	},
	{
		label: 'Washington',
		value: 'WA',
	},
	{
		label: 'Wisconsin',
		value: 'WI',
	},
	{
		label: 'West Virginia',
		value: 'WV',
	},
	{
		label: 'Wyoming',
		value: 'WY',
	},
];

const states = statesShort.map((item) => ({ value: item.label, label: item.label }));

const farmPackages = [
	{
		title: 'Premier Agent (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`Marketing to <b>1000 Sellers</b>`,
			`Seller Calls Performed Quarterly`,
			`Seller Texts Performed Quarterly`,
			`Seller Emails Performed Monthly (Marketing Designs Included)`,
			`Premier Agent Advertising`,
			`NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`FSBO (For Sale By Owner) Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`Unlimited BPO Tech Training Center Access`,
			`BPO Report Ordering Page`,
			`Luxury Investor Website`,
			`Investor Log (Investor List Available for Purchase)`,
			`MDA (Million Dollar Agent) Business Program`,
			`20% Referral Fee Required from BPO Homes Pre-Qualified Leads`,
			`Lofty CRM Included`

		],
	},
	{
		title: 'Premier Agent Pro (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`Marketing to <b>2000 Sellers</b>`,
			`Seller Calls Performed Quarterly`,
			`Seller Texts Performed Quarterly`,
			`Seller Emails Performed Monthly (Marketing Designs Included)`,
			`Premier Agent Advertising`,
			`NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`FSBO (For Sale By Owner) Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`Unlimited BPO Tech Training Center Access`,
			`BPO Report Ordering Page`,
			`Luxury Investor Website`,
			`Investor Log (Investor List Available for Purchase)`,
			`MDA (Million Dollar Agent) Business Program`,
			`20% Referral Fee Required from BPO Homes Pre-Qualified Leads`,
			`Lofty CRM Included`

		],
	},
	{
		title: 'Premier Agent Elite (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`Marketing to <b>3000 Sellers</b>`,
			`Seller Calls Performed Quarterly`,
			`Seller Texts Performed Quarterly`,
			`Seller Emails Performed Monthly (Marketing Designs Included)`,
			`Premier Agent Advertising`,
			`NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`FSBO (For Sale By Owner) Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`Unlimited BPO Tech Training Center Access`,
			`BPO Report Ordering Page`,
			`Luxury Investor Website`,
			`Investor Log (Investor List Available for Purchase)`,
			`MDA (Million Dollar Agent) Business Program`,
			`20% Referral Fee Required from BPO Homes Pre-Qualified Leads`,
			`20% Referral Fee from VA Leads`,
			`Lofty CRM Included`,
			`Full Time Virtual Assistant Included`

		],
	},
	{
		title: 'Premier Agent Ultra (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`Marketing to <b>4000 Sellers</b>`,
			`Seller Calls Performed Quarterly`,
			`Seller Texts Performed Quarterly`,
			`Seller Emails Performed Monthly (Marketing Designs Included)`,
			`Premier Agent Advertising`,
			`NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`FSBO (For Sale By Owner) Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`Unlimited BPO Tech Training Center Access`,
			`BPO Report Ordering Page`,
			`Luxury Investor Website`,
			`Investor Log (Investor List Available for Purchase)`,
			`MDA (Million Dollar Agent) Business Program`,
			`20% Referral Fee Required from BPO Homes Pre-Qualified Leads`,
			`10% Referral Fee from VA Leads`,
			`Lofty CRM Included`,
			`Full Time Virtual Assistant Included`

		],
	},
];

const schedule = [
	{
		name: 'Email Campaigns',
		starter_days_to_add: 30,
		pro_days_to_add: 30,
		elite_days_to_add: 30,
	},
	{
		name: 'MMS Campaigns',
		starter_days_to_add: 30,
		pro_days_to_add: 30,
		elite_days_to_add: 30,
	},
	// {
	// 	name: 'Facebook Ads',
	// 	starter_days_to_add: 30,
	// 	pro_days_to_add: 30,
	// 	elite_days_to_add: 30,
	// },
	{
		name: 'Post Card Mailers',
		starter_days_to_add: 180,
		pro_days_to_add: 90,
		elite_days_to_add: 30,
	},
	{
		name: 'Seller Calling',
		starter_days_to_add: 180,
		pro_days_to_add: 180,
		elite_days_to_add: 90,
	},
];

//array of time zones in usa

const timeZones = [
	{
		value: 'Eastern Time Zone',
		label: 'Eastern Time Zone',
	},
	{
		value: 'Hawaii Time Zone',
		label: 'Hawaii Time Zone',
	},
	{
		value: 'Mountain Time Zone',
		label: 'Mountain Time Zone',
	},
	{
		value: 'Central Time Zone',
		label: 'Central Time Zone',
	},
	{
		value: 'Atlantic Time Zone',
		label: 'Atlantic Time Zone',
	},
	{
		value: 'Pacific Time Zone',
		label: 'Pacific Time Zone',
	},
	{
		value: 'Alaska Time Zone',
		label: 'Alaska Time Zone',
	},
];

const specialties = ['', 'Buyer Agent', 'Listing Agent', 'Relocation', 'Short Sale', 'Foreclosure', 'Consulting', 'Other'];
const languages = [
	'',
	'English',
	'Spanish',
	'French',
	'German',
	'Mandarin',
	'Cantonese',
	'Arabic',
	'Hindi',
	'Polish',
	'Italian',
	'Thai',
	'Korean',
	'Greek',
];

const headerImages = [
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage1.jpg',
		title: 'Header 1',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage2.jpg',
		title: 'Header 2',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage3.jpg',
		title: 'Header 3',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage4.jpg',
		title: 'Header 4',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage5.jpg',
		title: 'Header 5',
	},
];

const ratingOptions = [
	{ label: 'Local Knowledge', value: 'local_knowledge' },
	{ label: 'Process Expertise', value: 'process_expertise' },
	{ label: 'Responsiveness', value: 'responsiveness' },
	{ label: 'Negotiation Skills', value: 'negotiation_skills' },
];

const mapPolygonStyles = { fillColor: '#FFAA01', strokeColor: '#1378A5' };
const exteriorList = ['Street View', 'Front House', 'Left Side', 'Right Side', 'Backyard', 'Other Exterior photos'];
const interiorList = ['Kitchen', 'Bathroom(s)', 'Floorings', 'Other Interior photos'];

const additionalFields = [
	{
		label: 'Home Style',
		amount: 'home_style_value',
		feedback: 'home_style_feedback',
		tooltip: 'This indicates if the this style of home is up with the current desirable Market Trends for the area',
	},
	{
		label: 'Feng Shui',
		amount: 'feng_shui_value',
		feedback: 'feng_shui_feedback',
		tooltip: 'This indicates if the floor plan or flow of the home is desirable',
	},
	{
		label: 'Proximity to Amenities or Neutral',
		amount: 'proximity_to_amenities_value',
		feedback: 'proximity_to_amenities_feedback',
		tooltip: 'Property Near Parks, Shopping, Walking Trails, Dinning, Cul De Sac',
	},
	{
		label: 'Proximity Neighborhood Negatives & Nuisances or Neutral',
		amount: 'proximity_neighborhood_negative_value',
		feedback: 'proximity_neighborhood_negative_feedback',
		tooltip: 'Freeways, Busy Streets, Far Away from shopping & Dining',
	},
];
const property_condition_options = ['Poor', 'Average', 'Above Average', 'Excellent Condition'];

const investorTypes = [
	{
		label: 'Flips',
		name: 'flips',
	},
	{
		label: 'Development',
		name: 'development',
	},
	{
		label: 'Home additions',
		name: 'home-additions',
	},
	{
		label: 'Rentals',
		name: 'rentals',
	},
];

const mainWithOptions = [
	{
		name: 'Electrical',
		options: [
			'EV chargers',
			'Electrical wiring',
			'Emergency electrical services',
			'Landscape lighting',
			'Electrical repairs',
			'Rewiring',
			'Surge protector',
			'Ceiling fan installation',
			'Chandelier installation',
			'Custom lighting',
			'Electrical Construction',
			'Generator installation',
			'Outlet and switch repair',
			'Electrical inspections',
			'Remodels',
			'Safety inspections',
			'Appliance circuits',
			'Electrical upgrades',
			'Home automation',
			'Recessed lighting',
			'Smoke detectors',
			'Aluminum wiring',
			'Electrical maintenance',
			'Electrical panels',
		],
	},
	{
		name: 'Plumbing',
		options: [
			'Drain cleaning and unclogging',
			'Water heater maintenance',
			'Sewer line inspections and repairs',
			'Mold remediation',
			'Garbage disposal repairs and replacement',
			'Pipe restoration and excavation',
			'Water damage cleanup',
			'Leak repairs',
			'Sewer Line Install',
			'Piping',
			'Septics',
			'Well',
			'Wells',
			'Potable Water Systems',
			'Public Sewer Systems',
			'Buy Water Heater',
			'Water Heater Repair',
			'Water Heater Install',
			'Tankless Water Heater',
			'Tankless Water Heater Install',
			'Water Heater Replacement',
			'Tankless Water Heater Repair',
			'PVC',
			'ABS',
			'Sewer Line',
			'Copper Plumbing',
			'Galvanized Plumbing Repairs',
			'Galvanized Plumbing',
		],
	},
	{
		name: 'Roofing',
		options: [
			'Roof replacement',
			'Leak repair',
			'Roof patching',
			'Gutter installation and repair',
			'Roof inspections',
			'Flashing fabrication and installation',
			'New roof installation',
			'Moss Growth Removal',
			'Roof Install',
			'Tar & Gravel Roof',
			'Torch Down Roof',
			'Foam Roof',
			'Composition Roof',
			'Composition Asphalt Shingle Roof',
			'Asphalt Shingle Roof',
			'Gable Roof',
			'Flat Roof',
			'Gambrel Roof',
			'Mansard Roof',
			'Hip Roof',
			'Metal Roofing',
			'Slate Roof',
			'Butterfly Roof',
			'Concrete Tiles',
			'Shed Roof',
			'Dutch Gable Roof',
			'Wood Shake Roof',
			'Shake Roof',
			'Dormer',
			'Dormers',
			'Rubber Roofing',
			'Shingles',
			'Membrane Roofing',
			'Saltbox',
			'Gutter Install',
			'Sheathing Install',
			'Roof Sheathing Install',
			'Spanish Tiles Roof',
			'Spanish Roof Tiles',
			'Spanish Tiles',
			'Skylights',
			'Skylight Install',
			'Moss Growth',
			'Moss Removal',
		],
	},
	{
		name: 'Insulation',
		options: [
			'Wall insulation',
			'Spray foam',
			'Cellulose insulation',
			'Fiberglass batt insulation',
			'Glass wool',
			'Mineral wool',
			'Rigid board insulation',
			'BIBS insulation',
			'Floors',
			'Air sealing',
			'Blown In Insulation',
			'Blown Insulation',
			'Closed Cell Spray Foam: R-6 to R-7',
			'Foam Board: R-4.5 to R-5',
			'RetroFoam Injection Foam: R-4.6 to R-5',
			'Open Cell Spray Foam: R-3.6 to R-3.9',
			'Cellulose (Loose-Fill): R-3.1 to R-3.8',
			'Fiberglass (Batts): R-2.9 to R3.8',
			'Fiberglass (Loose-Fill): R-2.2 to R-2.9',
			'R-1 Insulation',
			'R-1.5',
			'R-2',
			'R-2.5',
			'R-3',
			'R-3.5',
			'R-4',
			'R-4.5',
			'R-4.6',
			'R-5.5',
			'R-6',
			'R-7',
		],
	},
	{
		name: 'Flooring',
		options: [
			'Tile',
			'Laminate flooring',
			'Carpet',
			'Hardwood flooring',
			'Vinyl Plank Flooring',
			'Engineered wood',
			'Linoleum',
			'Stone',
			'Cork',
			'Hardwood',
			'Vinyl',
			'Bamboo flooring',
			'Concrete',
			'Bamboo',
			'Cantera flooring',
			'Bathroom flooring',
			'Hybrid flooring',
			'Parquet flooring',
			'Resin flooring',
			'Carpet tiles',
			'Concrete floors',
			'Other types of flooring',
			'Flooring Install',
			'Flooring Removal',
			'Flooring Damage',
			'Flooring Repair',
		],
	},
	{
		name: 'Handyman',
		options: [
			'Plumbing',
			'Carpentry',
			'Painting',
			'Electricity',
			'Furniture assembly',
			'Pressure washing',
			'Deck repairs',
			'Gutter cleaning',
			'Smart home upgrades',
			'Tiling',
			'Fixture Replacement',
			'Repairs',
			'Fix fences',
			'Floor improvements',
			'HVAC',
			'Landscaping',
			'Art hanging',
			'Babyproofing',
			'Drywall repair',
			'Door adjustments',
			'Drywall',
			'Skylight installation',
			'Window coverings',
			'Door repair',
			'Miscellaneous Repairs',
			'Home Improvement',
		],
	},
	{
		name: 'Landscaping',
		options: [
			'Lawn mower',
			'Mulch',
			'Landscape design',
			'Weed control',
			'Fertilization',
			'Lawn aerator',
			'Pruning',
			'Mowing',
			'Pruning shrubs',
			'Snow removal',
			'Bush trimming',
			'Irrigation',
			'Dethatching',
			'Edging',
			'Planting',
			'Gutter cleaning',
			'Hedging',
			'Leaf removal',
			'Lawn returfing',
			'Trimming',
			'Lawn bed maintenance',
			'Pest control',
			'Clean up',
			'Sod Installation',
			'Grass Installation',
			'Flower Planting',
			'Plant Planting',
			'Tree Planting',
			'Tree Removal',
			'Irrigation startup or shutdown services',
		],
	},
	{
		name: 'General Contractor',
		options: [
			'Remodeling',
			'Remodel Kitchen',
			'Remodel Baths',
			'Framing',
			'Electrical',
			'Plumbing',
			'Roofing',
			'Flooring',
			'Tiles',
			'Landscaping',
			'Concrete',
			'Electrical Contactor',
			'Roofing Contractor',
			'Commercial Contractor',
			'GC',
			'General Contractor',
			'Residential Contractor',
			'New Construction',
			'Rebuild',
			'Demolition',
			'Demo',
			'Gut',
			'Insulation',
			'Foundation',
			'Structural Engineering',
		],
	},
	{
		name: 'Garage Door',
		options: ['Garage Door Repair', 'Garage Door Install', 'Garage Door', 'Buy Garage Door', 'Purchase Garage Door'],
	},

	{
		name: 'HVAC',
		options: ['Heat', 'Air', 'Duct Installation', 'Air Duct Install', 'Heating Duct Install', 'Duct Install', 'HVAC', 'Buy AC Unit', 'Buy Furnace'],
	},
	{
		name: 'Home Staging',
		options: ['Staging', 'Stage my House', 'Stager'],
	},
	{
		name: 'Painting',
		options: ['Painting', 'Painter'],
	},
	{
		name: 'Home Design',
		options: ['Homes Design', 'Homes Designer'],
	},
	{
		name: 'Fire Safety',
		options: [
			'Inspections',
			'Maintenance',
			'Fire Sprinkler System Design',
			'Fire Sprinkler',
			'Maintenance Inspections',
			'Fire Sprinkler Maintenance',
			'Fire Suppression',
			'FM 200 Inspections',
			'Kitchen Suppression',
			'Commercial Sprinklers',
			'Residential Sprinklers',
			'Residential Fire Sprinkler Inspections',
			'Commercial Fire Sprinkler Inspections',
			'Fire Sprinkler Install',
			'Fire Sprinkler Plumbing',
			'Fire Sprinkler Monitoring',
			'Fire Sprinkler System Repair',
		],
	},
	{
		name: 'House/ Home Cleaning',
		options: ['House Cleaning', 'Kitchen Cleaning', 'Bedroom Cleaning', 'Bathroom Cleaning', 'Dusting'],
	},
	{
		name: 'Kitchen Counter Suppliers',
		options: [
			'Solid Surface Countertops',
			'Butcher Block Countertops',
			'Laminate Countertops',
			'Soapstone',
			'Ceramic Tile',
			'Stainless Steel',
			'Kitchen Counters',
			'Granite Counter Tops',
			'Quartz Counter Tops',
			'Marble Counter Tops',
			'Kitchen Cabinets',
			'Custom Kitchen Cabinets',
		],
	},
	{
		name: 'Tile Suppliers',
		options: [
			'Bathroom Tile',
			'Flooring & Carpet',
			'Kitchen Flooring',
			'Material',
			'Ceramic',
			'Porcelain',
			'Stone',
			'Stores',
			'Home Depot',
			"Lowe's",
			'Walmart',
			'Square Tiles',
			'Rectangle Tiles',
			'Rectangular Tiles',
			'Hexagon Tiles',
			'Herringbone',
			'Matte',
			'Glossy',
			'Glazed',
			'Polished',
			'Large Format Tiles',
			'Standard Format Tiles',
			'Mosaic',
			'Features',
			'Outdoor Tiles',
			'Tiles With Water Protection',
			'Accent Tiles',
			'GREENGUARD Certified Tiles',
			'Pressed Tiles',
			'Straight Edge Tiles',
			'Rectified Tiles',
			'Contemporary Tiles',
			'Modern Tiles',
			'Farmhouse Tiles',
			'Classic Tiles',
			'Ivy Hill Tile',
			'Somertile',
			'MSI',
			'Daltile',
			'Marazzi Tile',
			'Emser Tile',
			'TrafficMASTER',
			'Bedrosians',
		],
	},
	{
		name: 'Window Suppliers',
		options: [
			'Awning windows',
			'Casement windows',
			'Double hung windows',
			'Single hung windows',
			'Sliding windows',
			'Picture windows',
			'Bay windows',
			'Bow windows',
			'Skylights',
			'Hopper windows',
			'Transom windows',
			'Garden windows',
			'Slider windows',
			'Fixed windows',
			'Glass block windows',
			'Jalousie windows',
			'Storm windows',
			'Egress windows',
			'Ingress windows',
			'Shaped windows',
			'Arched windows',
			'Round windows',
			'Casement',
			'Dormer window',
			'Single hung',
		],
	},
	{
		name: 'Architect',
		options: [
			'Residential Plans',
			'Commercial Plans',
			'Home Design',
			'Building Design',
			'Architectural Engineering',
			'Structural Engineering',
			'Plan Design',
			'Residential Architect',
			'Commercial Architect',
		],
	},
	{
		name: 'Mold Abatement',
		options: ['Mold Removal'],
	},
	{
		name: 'Junk Removal',
		options: ['Junk Removal', 'Junk Hauling', 'Demolition', 'Demo', 'Gut', 'Property Gutting'],
	},
	{
		name: 'Asbestos Abatement',
		options: ['Asbestos Removal'],
	},
	{
		name: 'Mortgage Brokers',
		options: ['Lending', 'Mortgage Rates', 'Loan'],
	},
	{
		name: 'Homes Insurance',
		options: ['Asbestos Removal'],
	},
];

export {
	mainWithOptions,
	sellList,
	buyList,
	analyticsList,
	professionalOptions,
	states,
	timeZones,
	URL,
	statesShort,
	farmPackages,
	schedule,
	specialties,
	languages,
	headerImages,
	ratingOptions,
	mapPolygonStyles,
	additionalFields,
	exteriorList,
	interiorList,
	property_condition_options,
	investorTypes,
};
