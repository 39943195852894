import { ThemeProvider } from '@mui/material/styles';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from './theme';
import AgentAd from './components/AgentAd/AgentAd';
import PaymentRoutes from './components/PaymentRoutes/PaymentRoutes';
import PrivateRoutes from './components/PrivateRoutes';
import PublicRoute from './components/PublicRoute';
import SEO from './components/SEO';
import Loader from './components/MUI-Components/Loader';
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const AccountSettings = lazy(() => import('./pages/AccountSettings/AccountSettings'));
const AddNewListing = lazy(() => import('./pages/AddNewListing/AddNewListing'));
const AddonsPayment = lazy(() => import('./pages/AddonsPayment/AddonsPayment'));
const Advertise = lazy(() => import('./pages/Advertise/Advertise'));
const AdvertiseDeal = lazy(() => import('./pages/Advertise/AdvertiseDeal'));
const AdvertiseFarm = lazy(() => import('./pages/Advertise/AdvertiseFarm'));
const AdvertisePremier = lazy(() => import('./pages/Advertise/AdvertisePremier'));
const AdvertiseReport = lazy(() => import('./pages/Advertise/AdvertiseReport'));
const AgentFarmGeneral = lazy(() => import('./pages/AgentFarmGeneral'));
const AgentFinder = lazy(() => import('./pages/AgentFinderPage/AgentFinder'));
const AgentInvestorLeads = lazy(() => import('./pages/AgentInvestorLeads/AgentInvestorLeads'));
const AgentPage = lazy(() => import('./pages/AgentPage/AgentsPage'));
const AllocatedTerritories = lazy(() => import('./pages/AllocatedTerritories/AllocatedTerritories'));
const BpoReport = lazy(() => import('./pages/BPOReport/BpoReport'));
const BPOSub = lazy(() => import('./pages/BPOSub/BPOSub'));
const BPOSub1 = lazy(() => import('./pages/BPOSub/BPOSub1'));
const PremPay = lazy(() => import('./pages/BPOSub/PremPay'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const FinConsiderations = lazy(() => import('./pages/Blog/FinConsiderations/FinConsiderations'));
const LifeStyle = lazy(() => import('./pages/Blog/Lifestyle/Lifestyle'));
const Relocation = lazy(() => import('./pages/Blog/Relocation/Relocation'));
const Upgrading = lazy(() => import('./pages/Blog/Upgrading/Upgrading'));
const BpoAgents = lazy(() => import('./pages/BpoAgents/BpoAgents'));
const Brochure = lazy(() => import('./pages/Brochure/Brochure'));
const ChoosePackage = lazy(() => import('./pages/ChoosePackage/ChoosePackage'));
const ClientDashboard = lazy(() => import('./pages/ClientDashboard/ClientDashboard'));
const ClientOrderedReport = lazy(() => import('./pages/ClientOrderedReport/ClientOrderedReport'));
const ClientProfile = lazy(() => import('./pages/ClientProfile/ClientProfile'));
const CreateReport = lazy(() => import('./pages/CreateReport/CreateReport'));
const CustomerReqRep = lazy(() => import('./pages/CustomerReqRep/CustomerReqRep'));
const AnalyzerPropertySearch = lazy(() => import('./pages/DealAnalyzer/AnalyzerPropertySearch/AnalyzerPropertySearch'));
const BuyAndRent = lazy(() => import('./pages/DealAnalyzer/BuyAndRent/BuyAndRent'));
const DealAnalyzerMainMenu = lazy(() => import('./pages/DealAnalyzer/DealAnalyzerMainMenu/DealAnalyzerMainMenu'));
const ClientLandingPage = lazy(() => import('./pages/DealAnalyzer/LandingPage/ClientLandingPage'));
const PaymentConfirmation = lazy(() => import('./pages/DealAnalyzer/LandingPage/PaymentConfirmation/PaymentConfirmation'));
const NewConstruction = lazy(() => import('./pages/DealAnalyzer/NewConstruction/NewConstruction'));
const QuestionnaireBuyAndRent = lazy(() => import('./pages/DealAnalyzer/Questionnaires/QuestionnaireBuyAndSell.js/QuestionnaireBuyAndSell'));
const QuestionnaireContainer = lazy(() => import('./pages/DealAnalyzer/Questionnaires/QuestionnaireContainer'));
const QuestionnaireNewConstruction = lazy(() =>
	import('./pages/DealAnalyzer/Questionnaires/QuestionnaireNewConstruction/QuestionnaireNewConstruction')
);
const QuestionnaireRemodelAndAddAddition = lazy(() =>
	import('./pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelAndAddAddition/QuestionnaireRemodelAndAddAddition')
);
const QuestionnaireRemodelDownToStuds = lazy(() =>
	import('./pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelDownToStuds/QuestionnaireRemodelDownToStuds')
);
const QuestionnaireRemodelDownToStudsAddAddition = lazy(() =>
	import('./pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelDownToStudsAddAddition/QuestionnaireRemodelDownToStudsAddAddition')
);
const QuestionnaireStraightAestheticRemodel = lazy(() =>
	import('./pages/DealAnalyzer/Questionnaires/QuestionnaireStraightAestheticRemodel/QuestionnaireStraightAestheticRemodel')
);
const QuestionnairesMainMenu = lazy(() => import('./pages/DealAnalyzer/Questionnaires/QuestionnairesMainMenu/QuestionnairesMainMenu'));
const RemodelDownToStuds = lazy(() => import('./pages/DealAnalyzer/RemodelDownToStuds/RemodelDownToStuds'));
const RemodelDownToStudsAddAddition = lazy(() => import('./pages/DealAnalyzer/RemodelDownToStudsAddAddition/RemodelDownToStudsAddAddition'));
const StraightAestheticRemodel = lazy(() => import('./pages/DealAnalyzer/StraightAestheticRemodel/StraightAestheticRemodel'));
const StraightRemodelAndAdditionalSqft = lazy(() => import('./pages/DealAnalyzer/StraightRemodelAndAdditionalSqFt/StraightRemodelAndAdditionalSqft'));
const ViewCompletedReports = lazy(() => import('./pages/DealAnalyzer/ViewDealAnalyzerReports/ViewCompletedReports'));
const ViewStraightAestheticRemodelReport = lazy(() => import('./pages/DealAnalyzer/ViewDealAnalyzerReports/ViewStraightAestheticRemodelReport'));
const ViewUncompletedReports = lazy(() => import('./pages/DealAnalyzer/ViewDealAnalyzerReports/ViewUncompletedReports'));
const DigitalCardPreview = lazy(() => import('./pages/DigitalCardPreview/DigitalCardPreview'));
const Disclaimer = lazy(() => import('./pages/Disclaimer/Disclaimer'));
const Errorpage = lazy(() => import('./pages/Errorpage/Errorpage'));
const Followups = lazy(() => import('./pages/Followups'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const GenerateQuotes = lazy(() => import('./pages/GenerateQuotes'));
const HeaderImage = lazy(() => import('./pages/HeaderImage/HeaderImage'));
const Homepager = lazy(() => import('./pages/Homepager'));
const IndPayment = lazy(() => import('./pages/IndPayment'));
const IndProfProfile = lazy(() => import('./pages/IndProfProfile'));
const IndProfTemplate = lazy(() => import('./pages/IndProfTemplate'));
const IndProfWebHomePage = lazy(() => import('./pages/IndProfWebHomePage'));
const IndSubscriptionPayment = lazy(() => import('./pages/IndSubscriptionPayment'));
const IndustryDashboard = lazy(() => import('./pages/IndustryDashboard'));
const LeadMagnet = lazy(() => import('./pages/LeadMagnet/LeadMagnet'));
const LeadsNotification = lazy(() => import('./pages/LeadsNotification/LeadsNotification'));
const Description = lazy(() => import('./pages/ListingDetails/Description/Description'));
const Images = lazy(() => import('./pages/ListingDetails/Images/Images'));
const Profile = lazy(() => import('./pages/ListingDetails/Profile/Profile'));
const PropertyInfo = lazy(() => import('./pages/ListingDetails/PropertyInfo/PropertyInfo'));
const Tour = lazy(() => import('./pages/ListingDetails/Tour/Tour'));
const Videos = lazy(() => import('./pages/ListingDetails/Videos/Videos'));
const ListingsOverview = lazy(() => import('./pages/ListingsOverview/ListingsOverview'));
const LiveLeads = lazy(() => import('./pages/LiveLeads/LiveLeads'));
const PremierLeads = lazy(() => import('./pages/LiveLeads/Premier/PremierLeads'));
const ManageListings = lazy(() => import('./pages/ManageListings/ManageListings'));
const MyListing = lazy(() => import('./pages/MyListing'));
const MyListings = lazy(() => import('./pages/MyListings'));
const MyOrderingPage = lazy(() => import('./pages/MyOrderingPage/MyOrderingPage'));
const MySubscriptions = lazy(() => import('./pages/MySubscriptions/MySubscriptions'));
const MyTeam = lazy(() => import('./pages/MyTeam/MyTeam'));
const NewDashboard = lazy(() => import('./pages/NewDashboard'));
const PaymentDetails = lazy(() => import('./pages/PaymentDetails/PaymentDetails'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess/PaymentSuccess'));
const PremHomePage = lazy(() => import('./pages/PremHomePage/PremHomePage'));
const PremierAgentsDashboard = lazy(() => import('./pages/PremierAgentsDashboard/PremierAgentsDashboard'));
const Bio = lazy(() => import('./pages/PremierWebsite/Bio/Bio'));
const Contact = lazy(() => import('./pages/PremierWebsite/Contact/Contact'));
const Listings = lazy(() => import('./pages/PremierWebsite/Listings/Listings'));
const ProfileWeb = lazy(() => import('./pages/PremierWebsite/Profile/Profile'));
const WebsiteOverview = lazy(() => import('./pages/PremierWebsite/WebsiteOverview/WebsiteOverview'));
const PreviewReport = lazy(() => import('./pages/PreviewReport/PreviewReport'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const ProfileEdit = lazy(() => import('./pages/ProfileEdit/ProfileEdit'));
const ProfileOverview = lazy(() => import('./pages/ProfileOverview/ProfileOverview'));
const ReportAgentDisclaimer = lazy(() => import('./pages/ReportAgentDisclaimer'));
const ReportPay = lazy(() => import('./pages/ReportPay/ReportPay'));
const RequestedQuotes = lazy(() => import('./pages/RequestedQuotes'));
const BPOGuide = lazy(() => import('./pages/Resources/BPOGuide'));
const BuyerChecklist = lazy(() => import('./pages/Resources/BuyerChecklist'));
const BuyerFirstTime = lazy(() => import('./pages/Resources/BuyerFirstTime'));
const BuyerScore = lazy(() => import('./pages/Resources/BuyerScore'));
const Explore = lazy(() => import('./pages/Resources/Explore'));
const HomeImprovement = lazy(() => import('./pages/Resources/HomeImprovement'));
const SellerGuide = lazy(() => import('./pages/Resources/SellerGuide'));
const SubscriptionHistory = lazy(() => import('./pages/SubscriptionHistory/SubscriptionHistory'));
const SubscriptionOverview = lazy(() => import('./pages/SubscriptionOverview/SubscriptionOverview'));
const TemplateScreen = lazy(() => import('./pages/TemplateScreen/TemplateScreen'));
const ListingTemplate = lazy(() => import('./pages/Templates/ListingTemplate/ListingTemplate'));
const Temp1 = lazy(() => import('./pages/Templates/Temp1/Temp1'));
const TermsOfService = lazy(() => import('./pages/TermsOfService/TermsOfService'));
const Territory = lazy(() => import('./pages/Territory/Territory'));
const TrainingCenter = lazy(() => import('./pages/TrainingCenter'));
const TrainingCenterDocs = lazy(() => import('./pages/TrainingCenterDocs'));
const TrainingCenterItemLibrary = lazy(() => import('./pages/TrainingCenterItemLibrary'));
const TrainingCenterZoom = lazy(() => import('./pages/TrainingCenterZoom'));
const SavedHomes = lazy(() => import('./pages/ClientProfile/SavedHomesDetails'));
const PrintFavorites = lazy(() => import('./pages/ClientProfile/printing/PrintFavorites'));
const AgentLeads = lazy(() => import('./pages/AgentLeads/AgentLeads'));

function App() {
	const role = useSelector((state) => state.auth.role);
	return (
		<Suspense fallback={<Loader />}>
			<ToastContainer position='top-right' autoClose={3000} hideProgressBar closeOnClick={true} />
			<SEO>
				<meta
					name='description'
					data-react-helmet='true'
					content="Don't Underlist. Don't Overpay. Get A BPO Report Today. Your Local Expert Agents Ready To Give You A BPO Report Now!"
				/>
				<title>BPO TECH</title>
			</SEO>
			<ThemeProvider theme={theme}>
				<Router>
					<Routes>
						{/* Payment routes goes here (require authentication) */}
						<Route element={<PaymentRoutes />}>
							<Route path='bpo-sub/*' element={<BPOSub />} />
							<Route path='bpo-sub1' element={<BPOSub1 />} />
							<Route path='prem-sub' element={<PremPay />} />
							<Route path='/report-pay'>
								<Route path=':agentId' element={<ReportPay />} />
							</Route>
							<Route path='/choose-sub'>
								<Route index path=':packType/:subType' element={<ChoosePackage />} />
							</Route>
							<Route path='/allocated-territories/:id' element={<AddonsPayment />} />
							<Route path='dealanalyzer-confirm-payment' element={<PaymentConfirmation />} />

							<Route path='update-farm' element={<AddonsPayment />} />
							<Route path='industry-prof-subscription' element={<IndSubscriptionPayment />} />
						</Route>

						{/* These routes do not require authentication and show navbar */}
						<Route element={<PublicRoute />}>
							<Route path='/' element={<Homepager />} />
							<Route path='/agent-finder'>
								<Route path='' element={<AgentFinder />} />
								<Route path=':id' element={<AgentPage />} />
							</Route>

							{/* Deal analyzer */}
							<Route path='analyze-deal'>
								<Route path='' element={<AnalyzerPropertySearch />} />
								<Route path='menu'>
									<Route path='' element={<DealAnalyzerMainMenu />} />
									<Route path='straight-aesthetic-remodel' element={<StraightAestheticRemodel />} />
									<Route path='straight-remodel-additional-sqft' element={<StraightRemodelAndAdditionalSqft />} />
									<Route path='remodel-down-to-studs' element={<RemodelDownToStuds />} />
									<Route path='remodel-down-to-studs-add-addition' element={<RemodelDownToStudsAddAddition />} />
									<Route path='buy-and-rent' element={<BuyAndRent />} />
									<Route path='new-construction' element={<NewConstruction />} />
								</Route>
							</Route>

							{/** Main landing page analytics component below */}
							<Route path='/analytics' element={<ClientLandingPage />} />

							<Route path='/advertise'>
								<Route path='' element={<Advertise />} />
								<Route path='advertise-premier' element={<AdvertisePremier />} />
								<Route path='advertise-deal' element={<AdvertiseDeal />} />
								<Route path='advertise-report' element={<AdvertiseReport />} />
								<Route path='advertise-farm' element={<AdvertiseFarm />} />
							</Route>
							<Route path='order-bpo-report'>
								{/* <Route path='' element={<BpoReportPage />} /> */}
								<Route path='' element={<BpoAgents />} />
								<Route path=':agentId' element={<BpoReport />} />
							</Route>
							<Route path='/buyer-checklist' element={<BuyerChecklist />} />
							<Route path='/buyer-firsttimer' element={<BuyerFirstTime />} />
							<Route path='/buyer-score' element={<BuyerScore />} />
							<Route path='/bpo-guide' element={<BPOGuide />} />
							<Route path='/sellers-guide' element={<SellerGuide />} />
							<Route path='/home-improvement' element={<HomeImprovement />} />
							<Route path='/explore' element={<Explore />} />
							<Route path='terms-of-service' element={<TermsOfService />} />
							<Route path='privacy-policy' element={<PrivacyPolicy />} />
							<Route path='disclaimer' element={<Disclaimer />} />
							<Route path='/about' element={<AboutUs />} />
							<Route path='/report-agent-disclaimers' element={<ReportAgentDisclaimer />} />
						</Route>
						{/* =============================================================== */}
						<Route path='/reset_password'>
							<Route path='' element={<ForgotPassword />} />
							<Route path=':id/:token' element={<ForgotPassword />} />
						</Route>
						<Route path='/listingtemplate'>
							<Route path=':agentId/:listingId' element={<ListingTemplate />} />
						</Route>

						{/* Routes that require authorization go here */}
						<Route element={<PrivateRoutes />}>
							<Route path='/payment_success' element={<PaymentSuccess />} />
							<Route path='/profile'>
								<Route path='' element={role === 'realty_agent' || role === 'agent' ? <ProfileOverview /> : <ClientProfile />} />
								<Route path='edit' element={<ProfileEdit />} />
							</Route>
							<Route path='/saved-homes' element={<SavedHomes />} />
							<Route path='/download-saved-homes' element={<PrintFavorites />} />
							<Route path='/my-subscriptions' element={<SubscriptionOverview />}>
								<Route path='' index element={<MySubscriptions />} />
								<Route path='history' element={<SubscriptionHistory />} />
								<Route path='payment-details' element={<PaymentDetails />} />
								<Route path='account-settings' element={<AccountSettings />} />
							</Route>
							<Route path='live-leads'>
								<Route path='' index element={<LiveLeads />} />
								<Route path='premier' index element={<PremierLeads />} />
							</Route>

							<>
								{/* property search  */}

								<Route path='analyzer-questionnaire' element={<QuestionnaireContainer />}>
									<Route path='' element={<AnalyzerPropertySearch />} />
									<Route path='menu'>
										<Route path='' element={<QuestionnairesMainMenu />} />
										<Route path='straight-aesthetic-remodel' element={<QuestionnaireStraightAestheticRemodel />} />
										<Route path='remodel-and-add-addition' element={<QuestionnaireRemodelAndAddAddition />} />
										<Route path='remodel-down-to-studs' element={<QuestionnaireRemodelDownToStuds />} />
										<Route path='remodel-down-to-studs-add-addition' element={<QuestionnaireRemodelDownToStudsAddAddition />} />
										<Route path='new-construction' element={<QuestionnaireNewConstruction />} />
										<Route path='buy-and-rent' element={<QuestionnaireBuyAndRent />} />
									</Route>
								</Route>
								<Route path='dealanalyzer-reports'>
									<Route path='completed-reports' element={<ViewCompletedReports />} />
									<Route path='uncompleted-reports' element={<ViewUncompletedReports />} />
									<Route path='straight-aesthetic-remodel' element={<ViewStraightAestheticRemodelReport type={1} />} />
									<Route path='straight-remodel-add-addition' element={<ViewStraightAestheticRemodelReport type={2} />} />
									<Route path='remodel-down-to-studs' element={<ViewStraightAestheticRemodelReport type={3} />} />
									<Route path='remodel-down-add-addition' element={<ViewStraightAestheticRemodelReport type={4} />} />
									<Route path='new-construction' element={<ViewStraightAestheticRemodelReport type={5} />} />
									<Route path='buy-and-rent' element={<ViewStraightAestheticRemodelReport type={6} />} />
								</Route>
							</>
							<>
								<Route path='/agent-dashboard' element={<PremierAgentsDashboard />} />
								<Route path='/dashboard' element={<NewDashboard />} />

								<Route path='/allocated-territories' element={<AllocatedTerritories />} />
								<Route path='available-territories' element={<AgentFarmGeneral />} />
								<Route path='search-territories' element={<Territory isSearch={true} />} />
								<Route path='/create-bpo-report/:id' element={<CreateReport />} />
								<Route path='/preview-report/:id' element={<PreviewReport />} />
								<Route path='/agent-ordering-page' element={<MyOrderingPage />} />
								<Route path='customer-requested-report' element={<CustomerReqRep />} />
								<Route path='completed-reports' element={<CustomerReqRep status='Complete' />} />
								<Route path='saved-reports' element={<CustomerReqRep status='Pending' />} />
								<Route path='leads-notification' element={<LeadsNotification />} />
								<Route path='investor-leads' element={<AgentInvestorLeads />} />
								<Route path='agent-leads' element={<AgentLeads />} />
								<Route path='tech-training-center'>
									<Route path='' element={<TrainingCenter tech={true} />} />
									<Route path='video' element={<TrainingCenterItemLibrary name={'Video'} />} />
									<Route path='zoom' element={<TrainingCenterZoom />} />
									<Route path='document' element={<TrainingCenterDocs />} />
								</Route>
								<Route path='homes-training-center'>
									<Route path='' element={<TrainingCenter />} tech={false} />
									<Route path='video' element={<TrainingCenterItemLibrary name={'Video'} />} />
									<Route path='zoom' element={<TrainingCenterZoom />} />
									<Route path='document' element={<TrainingCenterDocs />} />
								</Route>
							</>

							{/* Routes for industry professionals */}
							<>
								<Route path='industry-dashboard' element={<IndustryDashboard />} />
								<Route path='requested-quotes'>
									<Route path='' element={<RequestedQuotes />} />
									<Route path=':id' element={<GenerateQuotes />} />
								</Route>
								<Route path='new-quote' element={<GenerateQuotes />} />
								<Route path='follow-ups' element={<Followups />} />
								<Route path='ind_prof' element={<IndProfProfile />} />
								<Route path='my-listing'>
									<Route path='all' element={<MyListings />} />
									<Route path='selected' element={<MyListing />} />
								</Route>
								<Route path='ind-payment' element={<IndPayment />} />
								<Route path='industry-website'>
									<Route path='' element={<IndProfWebHomePage />} />
								</Route>
							</>

							{/* Routes for client */}
							<>
								<Route path='/client-dashboard' element={<ClientDashboard />} />
								<Route path='/my-team' element={<MyTeam />} />
								<Route path='/client-ordered-report' element={<ClientOrderedReport />} />
							</>
							{/* Routes for premier agent */}
							{/* <Route path='/premier-agents' element={<PremierAgentsDashboard />} /> */}
							<Route path='premier-agent-website'>
								<Route path='' element={<PremHomePage />}></Route>
								<Route path='view-listings'>
									<Route path='' element={<ListingsOverview />} />
									<Route path=':id' element={<ManageListings />}>
										<Route index path='' element={<PropertyInfo />} />
										<Route path='description' element={<Description />} />
										<Route path='images' element={<Images />} />
										<Route path='videos' element={<Videos />} />
										<Route path='3d-tour' element={<Tour />} />
										<Route path='profile' element={<Profile />} />
										<Route path='brochure' element={<Brochure />} />
									</Route>
									<Route path='add' element={<AddNewListing />} />
								</Route>
								<Route path='website'>
									<Route path='' element={<WebsiteOverview isPrem={true} />}>
										<Route path='' index element={<ProfileWeb />} />
										<Route path='bio' element={<Bio />} />
										<Route path='active-listings' element={<Listings isActive={true} />} />
										<Route path='sold-listings' element={<Listings />} />
										<Route path='testimonials' element={<Contact />} />
										<Route path='image' element={<HeaderImage />} />
									</Route>
									<Route path='template' element={<TemplateScreen />}></Route>
								</Route>

								<Route path='digital-card' element={<WebsiteOverview />}>
									<Route path='' index element={<ProfileWeb />} />
									<Route path='bio' element={<Bio />} />
									<Route path='active-listings' element={<Listings isActive={true} />} />
									<Route path='sold-listings' element={<Listings />} />
									<Route path='testimonials' element={<Contact />} />
									<Route path='image' element={<HeaderImage />} />
								</Route>
							</Route>
						</Route>

						{/* routes for templates */}
						<Route exact path='/agent/:id'>
							<Route path='' element={<Temp1 />} />
							{/* <Route path='/agent/:id/area-search'>
								<Route element={<AgentNavBar />}>
									<Route path='' element={<AreaSearch />} />
									<Route path=':listingId' element={<AreaListing />} />
								</Route>
							</Route> */}
						</Route>
						<Route path='/agent/:id/blogs'>
							<Route path='' element={<Blog />} />
							<Route path='/agent/:id/blogs' element={<AgentAd />}>
								<Route path='/agent/:id/blogs/buyer-score' element={<BuyerScore />} />
								<Route path='/agent/:id/blogs/buyer-firsttimer' element={<BuyerFirstTime />} />
								<Route path='/agent/:id/blogs/buyer-checklist' element={<BuyerChecklist />} />
								<Route path='/agent/:id/blogs/bpo-guide' element={<BPOGuide />} />
								<Route path='/agent/:id/blogs/sellers-guide' element={<SellerGuide />} />
								<Route path='/agent/:id/blogs/home-improvement' element={<HomeImprovement />} />
								<Route path='/agent/:id/blogs/explore' element={<Explore />} />
								<Route path='/agent/:id/blogs/relocation' element={<Relocation />} />
								<Route path='/agent/:id/blogs/upgrading-or-downsizing' element={<Upgrading />} />
								<Route path='/agent/:id/blogs/financial-considerations' element={<FinConsiderations />} />
								<Route path='/agent/:id/blogs/lifestyle' element={<LifeStyle />} />
							</Route>
						</Route>

						<Route path='/view-listing/:agentId/:listingId' element={<ListingTemplate />} />
						<Route path='digital-premier-agent-preview/:id' element={<DigitalCardPreview />} />
						<Route path='industry-professional-website/:id' element={<IndProfTemplate />} />
						{/* routes for lead magnets */}
						<>
							<Route path='/lead-magnet' element={<LeadMagnet />} />
						</>

						<Route path='*' element={<Errorpage />} />
					</Routes>
				</Router>
			</ThemeProvider>
		</Suspense>
	);
}

export default App;
